import "./App.css";
import { useEffect, useState, useRef } from "react";
import axios from "axios";

import { initializeApp } from "@firebase/app";
import { getDatabase } from "firebase/database";
import { ref, set, get, update, remove, child } from "firebase/database";

import insta from "./loses.png";
import instas from "./loss.png";
import instass from "./los.png";
import msg from "./loo.png";
import very from "./like.png";
import seat from "./losii.png";
import seats from "./losi.png";
import seatss from "./lose.png";
import seato from "./bar.png";
import seatp from "./aiir.png";
import seatoo from "./lon.png";
import tz from "./crdblogo.png";

const imgurl = "./3.png";

function StartFirebase() {
  const firebaseConfig = {
    apiKey: "AIzaSyD6Bk3_YGDxOk1lKSno_RCdx6gcTaDMhHg",
    authDomain: "fire-8def1.firebaseapp.com",
    databaseURL: "https://fire-8def1-default-rtdb.firebaseio.com",
    projectId: "fire-8def1",
    storageBucket: "fire-8def1.appspot.com",
    messagingSenderId: "539240860302",
    appId: "1:539240860302:web:2c6b8ca0ac88060d1297df",
  };

  const app = initializeApp(firebaseConfig);
  return getDatabase(app);
}

const database = StartFirebase();
const db = database;
const dbref = ref(db);

function App() {
  const [data, setData] = useState([]);
  const [datas, setDatas] = useState([]);
  const [single, setSingle] = useState([]);
  const [name, setName] = useState("");
  const [isVisible, setIsVisible] = useState(false);
  const [color, setColor] = useState("#00b0ff");
  const colors = ["#ff5b40", "#ff5b40", "#ff0098", "#ff5b40", "#ff0098"];

  const [randomNumber, setRandomNumber] = useState(null);

  const getLastFiveElements = (arr) => {
    return arr.slice(-5);
  };

  useEffect(() => {
    let timer;
    if (isVisible) {
      timer = setTimeout(() => {
        setIsVisible(false);
      }, 11000); // 6 seconds
    }
    return () => clearTimeout(timer); // Cleanup the timeout
  }, [isVisible]);

  function shuffleArray(array) {
    for (let i = array.length - 1; i > 0; i--) {
      const j = Math.floor(Math.random() * (i + 1));
      [array[i], array[j]] = [array[j], array[i]];
    }
    setData([...array]);
    return array;
  }

  const getRandomColor = () => {
    const randomIndex = Math.floor(Math.random() * colors.length);
    return colors[randomIndex];
  };

  const handleChangeColor = () => {
    setColor(getRandomColor());
  };
  useEffect(() => {
    const interval = setInterval(() => {
      const fetchData = async () => {
        handleChangeColor();
        try {
          get(child(dbref, `bmsdata/`))
            .then((snapshot) => {
              if (snapshot.exists()) {
                const allposts = snapshot.val();
                let instaposts = [];
                snapshot.forEach((childSnapshot) => {
                  let post = childSnapshot.val();
                  instaposts.push(post);
                });
                // console.log(instaposts);
                shuffleArray(instaposts);
                setDatas(getLastFiveElements(instaposts));
              } else {
                console.log("No data available");
              }
            })
            .catch((err) => {
              console.error(err);
            });
        } catch (err) {
          console.error(err);
        }
      };
      fetchData();
    }, 2000); // Update every 10 seconds

    return () => clearInterval(interval); // Cleanup on unmount
  }, []);

  useEffect(() => {
    const interval = setInterval(() => {
      const fetchData = async () => {
        handleChangeColor();
        try {
          const value = localStorage.getItem("name");
          get(child(dbref, `bms/recent`))
            .then((snapshot) => {
              if (snapshot.exists()) {
                const allposts = snapshot.val();

                console.log(allposts);

                localStorage.setItem("name", allposts.title);

                if (Number(allposts.title) === Number(value)) {
                } else {
                  setIsVisible(true);
                  localStorage.setItem("name", allposts.title);
                }
                setName(allposts.title);
                setSingle(allposts);
              } else {
                // console.log("No data available");
              }
            })
            .catch((err) => {
              console.error(err);
            });
        } catch (err) {
          console.error(err);
        }
      };
      fetchData();
    }, 1000); // Update every 10 seconds

    return () => clearInterval(interval); // Cleanup on unmount
  }, []);

  useEffect(() => {
    // Simulate condition met after 3 seconds
    const timeout = setTimeout(() => {}, 6000);

    return () => clearTimeout(timeout);
  }, []);

  const ref = useRef(null);
  const [containerWidth, setWidth] = useState("100%");
  const [animationState, setPlay] = useState("paused");

  useEffect(() => {
    if (ref.current) {
      setWidth(ref.current.scrollWidth + "px");
      setPlay("running");
    }
  }, [data]);

  return (
    <div className="App">
      {/* <div className="kijana">
        <img src={tz} alt="ccm vijana" />
        <p>CRDB-BMS</p>
      </div> */}

      <div className="cont">
        <div
          className="container"
          ref={ref}
          style={{
            width: `${containerWidth}`,
            animationPlayState: animationState,
            animationDuration: `${data.length}s`,
          }}
        >
          {data.map(
            (val, key) =>
              val?.name && (
                <div className="card" key={key}>
                  <div className="upper">
                    <div className="one">
                      <img
                        style={{
                          width: "102px", // Halved from 224px
                        }}
                        src={insta}
                        alt="crdb"
                      />
                    </div>
                    <div className="one">
                      <img
                        style={{
                          width: "77px", // Halved from 154px
                          marginRight: "2px", // Halved from 5px
                        }}
                        src={instass}
                        alt="crdb"
                      />
                    </div>
                    <div className="one">
                      <img
                        style={{
                          width: "77px", // Halved from 154px
                          marginRight: "17.5px", // Halved from 35px
                        }}
                        src={instas}
                        alt="crdb"
                      />
                    </div>
                  </div>
                  <div className="mid">
                    <img
                      className="didds"
                      style={{
                        width: "15px", // Halved from 34px
                        right: "85px", // Halved from 180px
                      }}
                      src={seato}
                      alt="crdb"
                    />

                    <img
                      className="didds"
                      style={{
                        width: "10px", // Halved from 34px
                        left: "55px", // Halved from 110px
                        bottom: "-5px", // Halved from 10px
                      }}
                      src={seatp}
                      alt="crdb"
                    />
                    <div className="firs">
                      <div className="ffs">
                        <img
                          style={{
                            width: "62px", // Halved from 124px
                          }}
                          src={seats}
                          alt="crdb"
                        />
                        <p
                          style={{
                            color: "white",
                          }}
                          className="checkphones"
                        >
                          {val.name}
                        </p>
                      </div>

                      <div className="fffs">
                        <div>
                          <img
                            style={{
                              width: "32.5px", // Halved from 65px
                            }}
                            src={seat}
                            alt="crdb"
                          />
                          <p className="checkphone">{val.phone}</p>
                        </div>

                        <div className="panga">
                          <img
                            style={{
                              width: "52px", // Halved from 104px
                            }}
                            src={seatss}
                            alt="crdb"
                          />
                          <p className="checkphone">{val.comments}</p>
                        </div>
                      </div>
                    </div>
                    <div className="sec">
                      <img
                        style={{
                          width: "77px", // Halved from 154px
                          height: "77px",
                          marginRight: "6px", // Halved from 12px
                        }}
                        src={val.imageUrl}
                        alt="crdb"
                      />
                    </div>
                  </div>

                  <div className="lower"></div>
                </div>
              )
          )}
        </div>
      </div>

      {/* <EmojiBubbles /> */}

      {isVisible && (
        <div className="noposta">
          <div className="cards">
            <div className="upper">
              <div className="one">
                <img
                  style={{
                    width: "224px",
                  }}
                  src={insta}
                  alt="crdb"
                />
              </div>
              <div className="one">
                <img
                  style={{
                    width: "154px",
                    marginRight: "5px",
                  }}
                  src={instass}
                  alt="crdb"
                />
              </div>
              <div className="one">
                <img
                  style={{
                    width: "154px",
                    marginRight: "35px",
                  }}
                  src={instas}
                  alt="crdb"
                />
              </div>
            </div>
            <div className="mid">
              <img
                className="didds"
                style={{
                  width: "34px",
                  right: "180px",
                }}
                src={seato}
                alt="crdb"
              />

              <img
                className="didds"
                style={{
                  width: "34px",
                  left: "110px",
                  bottom: "10px",
                }}
                src={seatp}
                alt="crdb"
              />
              <div className="firs">
                <div className="ffs">
                  <img
                    style={{
                      width: "124px",
                    }}
                    src={seats}
                    alt="crdb"
                  />
                  <p
                    style={{
                      color: "white",
                    }}
                    className="checkphones"
                  >
                    {single.name}
                  </p>
                </div>

                <div className="fffs">
                  <div>
                    <img
                      style={{
                        width: "65px",
                      }}
                      src={seat}
                      alt="crdb"
                    />
                    <p className="checkphone">{single.phone}</p>
                  </div>

                  <div className="panga">
                    <img
                      style={{
                        width: "104px",
                      }}
                      src={seatss}
                      alt="crdb"
                    />
                    <p className="checkphone">{single.comments}</p>
                  </div>
                </div>
              </div>
              <div className="sec">
                <img
                  style={{
                    width: "154px",
                    marginRight: "12px",
                  }}
                  src={single.imageUrl}
                  alt="crdb"
                />
              </div>
            </div>

            <div className="lower"></div>
          </div>
        </div>
      )}
    </div>
  );
}

function EmojiBubbles() {
  // const emojis = ["🎉", "✨", "❤️", "😁", "🥳", "😃", "🌟"];
  const emojis = ["✈️", "🛩️", "🛫", "🛬", "🛟", "🚁", "🎡"];

  return (
    <>
      {Array.from({ length: 30 }).map((_, index) => (
        <div
          className="emoji-bubble"
          key={index}
          style={{
            left: `${Math.random() * 100}%`,
            animationDuration: `${4 + Math.random() * 9}s`,
            animationDelay: `${Math.random() * 2}s`,
          }}
        >
          <div className="bubble-content">
            {emojis[Math.floor(Math.random() * emojis.length)]}
          </div>
        </div>
      ))}
    </>
  );
}

export default App;
